import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AuthStorage } from '@storage/auth.storage';
import { AppHelper } from '@platform/helpers';
import { RoleEnum } from '@enums/role.enum';
import { KeyService } from '@platform/api/key/key.service';
import { TranslationStorage } from '@platform/storage/translation.storage';
import { LanguageStorage } from '@platform/storage/language.storage';
import { LanguagesService } from '@platform/api/language/language.service';

@Injectable()
export abstract class BaseAuthGuard implements CanActivate {

  constructor(
    protected authStorage: AuthStorage,
    protected router: Router,
    protected keyService: KeyService,
    protected translationStorage: TranslationStorage,
    protected languageStorage: LanguageStorage,
    private languagesService: LanguagesService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authStorage.checkLoginState()
      .pipe(mergeMap(() => this.getLanguages()))
      .pipe(
        switchMap(() => {
          return this.keyService.getAllKeys()
            .pipe(tap(data => {
              this.translationStorage.data = data.data;
            }));
        }),
        map(() => {
          if (this.authStorage.getTokenFromStorage()) {
            return this.checkSuccessResponse();
          } else {
            throw new Error();
          }
        }),
        catchError(() => this.checkAuthFail()),
      );
  }

  protected checkRoleOrNavigate(roles: RoleEnum[]) {

    if (roles.includes(this.authStorage.getRoleFromToken())) {
      return true;
    } else {
      this.navigateByRole();
      return false;
    }
  }

  protected navigateByRole() {
    const route = AppHelper.GET_BASE_ROUTE(this.authStorage.getRoleFromToken());
    if (route) {
      this.router.navigate([ route ]);
    } else {
      this.authStorage.logout();
      this.router.navigate([ 'login' ]);
    }
  }

  private getLanguages() {
    return this.languagesService.getAllLanguages()
      .pipe(tap(data => {
        this.languageStorage.languages = data;
        if (this.languageStorage.languages.length && (!this.languageStorage.getLanguageFromStorage() || !this.languageStorage.getLanguageData())) {
          this.languageStorage.setLanguage(this.languageStorage.languages[0].id);
        }
      }));
  }

  protected abstract checkAuthFail();

  protected abstract checkSuccessResponse();

}
