import { Injectable } from '@angular/core';
import { IResponse } from '@platform/interfaces/request.interface';
import { ApiService } from '@platform/modules/http/classes/base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeyService extends ApiService {

  controller = 'key';

  public getAllKeys(): Observable<IResponse<{ [key: string]: string }>> {
    return this.httpClient.get('getAllKeysForWeb', { model: null });
  }
}
