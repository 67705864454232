import { Injectable } from '@angular/core';
import { HttpOptionsService } from '@platform/modules/http/services/http-options.service';
import { LanguagesModel } from '@platform/api/language/res/languages-model';

@Injectable({
  providedIn: 'root',
})
export class LanguageStorage {
  languages: LanguagesModel[] = [];
  private readonly storageKey: string = 'language';
  private _resources: object = {};
  constructor(
    private httpOptionsService: HttpOptionsService,
  ) {
  }

  setLanguage(language: number) {
    this.saveLanguageInStorage(language);
    location.reload();
  }

  getLanguageFromStorage(): number {
    return Number(localStorage.getItem(this.storageKey));
  }

  getLanguageData() {
    return this.languages.find(x => x.id === this.getLanguageFromStorage());
  }

  setLanguageInHeader() {
    this.httpOptionsService.setLanguage(this.getLanguageFromStorage());
  }

  private saveLanguageInStorage(language: number) {
    localStorage.setItem(this.storageKey, language.toString());
  }

  public getResourceValueByKey(key: string): string {
    return this._resources[key] || key || 'Translation missing';
  }

  setTranslations(resources: object): void {
    this._resources = resources;
  }

  // public setLanguage(language: string): void {
  //   this.saveLanguageInStorage(language);
  //   location.reload();
  // }

  // private saveLanguageInStorage(language: string): void {
  //   localStorage.setItem(this.storageKey, language.toString());
  // }

  // public getLanguageFromStorage(): string {
  //   return localStorage.getItem(this.storageKey) || 'en-US';
  // }

  private setLanguageInHeaders(): void {
    this.httpOptionsService.setLanguage(this.getLanguageFromStorage());
  }

}
