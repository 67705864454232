import { Injectable } from '@angular/core';
import { ApiService } from '@platform/modules/http/classes/base.service';
import { Observable } from 'rxjs';
import { LanguagesModel } from './res/languages-model';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService extends ApiService {

  controller = 'language';

  public getAllLanguages(): Observable<LanguagesModel[]> {
    return this.httpClient.get(`getAllLanguages`, { model: LanguagesModel });
  }
}
