import { Fillable } from '@decorators/fillable.decorator';
import { BaseModel } from '@platform/modules/http/classes/base.model';

export class LanguagesModel extends BaseModel {

  @Fillable()
  id: number;

  @Fillable()
  flagImageFileName: string;

  @Fillable()
  languageCulture: string;

  @Fillable()
  name: string;

  @Fillable()
  uniqueSeoCode: string;

  @Fillable()
  position: string;

  @Fillable()
  rightToLeft: boolean;

  @Fillable()
  createdDate: Date;

  @Fillable()
  keysCount: number;
}
