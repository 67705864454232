import { Pipe, PipeTransform } from '@angular/core';
import { TranslationStorage } from '@platform/storage/translation.storage';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private translationStorage: TranslationStorage,
  ) {} 

  transform(value: string, ...data: any[]): string {
    let translation: string = this.translationStorage.data[value] || '';
    for (let i = 0; i < data.length; i++)
      translation = translation.split(`{${i}}`).join(data[i]);
    return translation || value;
  }

}
