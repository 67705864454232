import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { ToasterModule } from '@platform/modules/toaster/toaster.module';
import { HttpModule } from '@platform/modules/http/http.module';
import { TranslatePipe } from '@pipes/translate.pipe';
import { StartupService } from '@platform/services/startup.service';
import localeHY from '@angular/common/locales/hy';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeHY, 'hy');

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ToasterModule,
    BrowserAnimationsModule,
    HttpModule.forRoot(),
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    TranslatePipe,
    // StartupService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (service: StartupService) => () => service.load(),
    //   deps: [StartupService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
